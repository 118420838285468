import React, { useEffect, useState } from 'react';

import {
  Container, 
  ButtonCloseModalNewPassword,
  ModalContainer,
  AsideLogo,
  AsideForm,
  PortalGif,
  ForgotPassword,
  LogoDireto,
  SectionSignIn,
  SpaceSignIn,
  ContainerLogoDireto
} from './styles';


import { Lock, Person } from '@mui/icons-material';
import { Button, CircularProgress, Modal, Typography } from '@mui/material';

import { useAppDispatch, useAppSelector } from '../../store';
import { closeModalNewPassword, loadingAuth, loginUser, newPasswordRequest } from '../../store/user';
import { useNavigate } from 'react-router-dom';
import { WrapperInput, CustomTextField } from './styles';
import fundo from '../../assets/login/background_login.jpeg';
import portalGif from '../../assets/login/portal.gif';
import direto from '../../assets/login/direto.svg';

const Login: React.FC = () => {
  const [typePassword, setTypePassword] = useState<boolean>(false);
  const [password, setPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [newConfirmPassword, setNewConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const dispatch = useAppDispatch()
  const loading = useAppSelector(loadingAuth)
  const user = useAppSelector(state => state.user);
  const navigate = useNavigate();

  const handleLogin = async() => {
    dispatch(loginUser({ email: email, password: password }));
  }

  const handleCloseModalNewPassword = () => dispatch(closeModalNewPassword());

  const handleSendNewPassword = () => {
    dispatch(newPasswordRequest({ password: newPassword }))
    handleCloseModalNewPassword();
  }

  useEffect(() => {
    if (user.name !== "") {
      navigate("/carteira-pre")
    }
  }, [user])

  return (
   <Container>
    <AsideLogo src={fundo} />
    <SectionSignIn>
      <SpaceSignIn />
      <AsideForm>
        <PortalGif src={portalGif}/>
        <WrapperInput>
          <Person
            fontSize={'small'}
            style={{
              height: 60,
              width: 60,
              backgroundColor: "#000",
              color: "#FFF"
            }}
          />
          <CustomTextField
            placeholder='Nome'
            type='email'
            onChange={(e) => setEmail(e.currentTarget.value)}
            value={email}
          />
        </WrapperInput>

        <WrapperInput>
          <Lock
            style={{
              height: 60,
              width: 60,
              backgroundColor: "#000",
              color: "#FFF",
            }}
          />
          <CustomTextField
            placeholder='Senha'
            type='password'
            onChange={(e) => setPassword(e.currentTarget.value)}
            value={password}
          />
        </WrapperInput>

        <Button variant="contained"
                style={{
                  backgroundColor: "#000",
                  width: 280,
                  height: 50,
                  borderRadius: 50,
                }}
                onClick={handleLogin}
        >
          {loading ?
            <CircularProgress
              style={{
                color: "#FFF"
              }}
              size={25}
            />
            :
            "Entrar"
          }
        </Button>

        <ForgotPassword>
          Esqueci a senha
        </ForgotPassword>

        <div>
          Build # {process.env.REACT_APP_BUILD}
        </div>
      </AsideForm>
      <ContainerLogoDireto>
        <LogoDireto src={direto}/>
      </ContainerLogoDireto>
    </SectionSignIn>
     <Modal
      open={user.newPassword}
      onClose={handleCloseModalNewPassword}
     >
      <ModalContainer>
        <Typography >Para realizar um login é necessário informar um nova senha!</Typography>
        <WrapperInput>
          <Lock
            style={{
              height: 60,
              width: 60,
              backgroundColor: "#000",
              color: "#FFF",
            }}
          />
          <CustomTextField
            placeholder='Senha'
            type='password'
            onChange={(e) => setNewPassword(e.currentTarget.value)}
            value={newPassword}
          />
        </WrapperInput>
        <WrapperInput>
          <Lock
            style={{
              height: 60,
              width: 60,
              backgroundColor: "#000",
              color: "#FFF",
            }}
          />
          <CustomTextField
            placeholder='Senha'
            type='password'
            onChange={(e) => {
              setNewConfirmPassword(e.currentTarget.value)
              setTypePassword(true)
            }}
            value={newConfirmPassword}
          />
        </WrapperInput>
        {(typePassword === true && newConfirmPassword === newPassword) && <Typography>Senhas Estão Iguais</Typography>}
        <ButtonCloseModalNewPassword onClick={handleSendNewPassword}>Confirmar</ButtonCloseModalNewPassword>
      </ModalContainer>
     </Modal>
   </Container>
  );
}

export default Login;
