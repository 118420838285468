import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Container, FormContainer, SubmitButton } from './styles';
import { Form } from './stylesDef';
import { DataTableFiltrosStatus } from './components/DataTableFiltrosStatus';
import { api } from '../../api';
import HeaderTitle from '../../components/HeaderTitle';
import { Box, Button, Grid } from '@mui/material';
import EditaFiltro from './components/EditaFiltro';

interface IFilterStatusProps {
 id: string;
 created: string;
 json_filters: string;
 name: string;
 processing: number;
 status: string;
}

const StatusFiltros: React.FC = () => {
  const [enableVisibleForm, setEnableVisibleForm] = useState<boolean>(false);
  const [editFilterId, setEditFilterId] = useState<string>('');
  const params = useParams();
  const methods = useForm({
    defaultValues: {
      nome_filtro: "",
      status: ""
    }
  });
  const [filtersStatus, setFiltersStatus] = useState<IFilterStatusProps[]>([]);
  const { fileId } = params;

  const getFilters = async() => {
   const toastID = toast.loading("Carregando Filtros")
   try{
    const response = await api.get(`/backend/portfolios/analysis/files/${fileId}/list`);
    setFiltersStatus(response.data.data)
   }catch(err){
    toast("Falha ao carregar os filtros")
   }finally{
    toast.dismiss(toastID);
   }
  }

  const publicaFiltro = async(data: any) => {
    setEnableVisibleForm(false)
    const loadingToastId = toast.loading("Salvando Edição de Filtro");
    try{
      await api.post(`/backend/portfolios/analysis/files/${fileId}/filters/${editFilterId}`, {
        id: editFilterId,
        nome_filtro: data.nome_filtro,
        status: data.status
      })
      const filterEdited = filtersStatus.map((filter) => {
        if(filter.id === editFilterId){
          return {
            ...filter,
            name: data.nome_filtro,
            status: data.status,
          }
        } else {
          return filter
        }
      })
      setFiltersStatus([...filterEdited]);
    } catch(err){
      toast.error("Falha ao editar filtro")
    } finally {
      toast.dismiss(loadingToastId);
    }
  }

  const onSaveFiltro = (data: any) => {
    setEnableVisibleForm(true);
    methods.setValue("nome_filtro", data.name ?? "")
    methods.setValue("status", data.status)
    setEditFilterId(data.id)
  }

  const onDeleteFilter = async(data: any) => {
    const toastLoadingId = toast.loading("Deletando filtro")
    try{
      await api.delete(`/backend/portfolios/analysis/files/${fileId}/filters/${data.id}`)
      const filterDeleted = filtersStatus.filter((filter) => filter.id !== data.id);
      setFiltersStatus([...filterDeleted]);
    }catch(err){
      toast.error("Falha ao deletar filtro")
    } finally {
      toast.dismiss(toastLoadingId)
    }
  }

  useEffect(() => {
   getFilters()
  }, [])
  return (
   <Container>
    <HeaderTitle 
      title='Status Filtro'
      variant='h4'
      style={{
        marginBottom: 20
      }}
    />
    <DataTableFiltrosStatus 
      data={filtersStatus} 
      handleSaveFiltro={onSaveFiltro}
      handleDeleteFilter={onDeleteFilter}
    />
      {enableVisibleForm && 
      <>
        <HeaderTitle 
          title='Salvar Filtro'
          variant='h4'
          style={{
            marginBottom: 10
          }}
        />
        <FormContainer>
          <Form onSubmit={methods.handleSubmit(publicaFiltro)}>
            <FormProvider {...methods}>
              <EditaFiltro />
            </FormProvider>
            <SubmitButton type="submit">
              Salvar
            </SubmitButton>
          </Form>
        </FormContainer>
      </>}
   </Container>
  );
}

export default StatusFiltros;