import { Box, styled } from "@mui/material";

export const Container = styled(Box)`
position: relative;
display: flex;
flex-direction: column;
padding: 1rem;
margin-bottom: 10rem;
`;

export const ChartDetails = styled(Box)`
 display: flex;
 width: 1000px;
 height: 200px;
 position: absolute;
 top: 110%;
 left: 55%;
 transform: translate(-50%, -50%);
`;

export const ChartMain = styled(Box)`

`;