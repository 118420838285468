import { Accordion, AccordionDetails, Box, Button, Chip, ChipProps, Select, styled } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

interface ICustomChip extends ChipProps {
 active: boolean;
}

export const CustomAccordion = styled(Accordion)`
 border-bottom: 1px solid #d9d9d9;
 padding: 0;
 margin: 1rem 0 2rem 0;
 background-color: #EFEFEF;
 border-radius: 8px;
`;

export const CustomAccordionDetails = styled(AccordionDetails)`
 margin-left: 2rem;
 margin-bottom: 1rem;
`;

export const CustomChip = styled(Chip)<ICustomChip>`
 background-color: ${ ({ active }) => active ? '#F46F62' : '#EBEBEB' };
 color: ${ ({ active }) =>  active ? '#FFF' : '#000' };
`;

export const FilterButton = styled(Button)`
background-color: #F46F62;
border: 1px solid #F46F62;
padding: 0.5rem 2rem;
border-radius: 30px;
color: #FFF;
transition: .3s;
cursor: pointer;
width: 200px;
margin-top: 1rem;

&:hover {
 border: 1px solid #F46F62;
 background-color: #FFF;
 color: #F46F62
}
`;

export const CustomSelect = styled(Select)`
 border-radius: 8px;
 background-color: #FFF;
 height: 2.5em;
 width: 100%
`;

export const CustomDatePicker = styled(DatePicker)`
 background-color: #FFF;
 width: 100%
`;