import { GridColDef } from '@mui/x-data-grid';
import React from 'react';

import { CustomDataGrid, DetailsButton, HeaderTable } from './styles';
import { useNavigate } from 'react-router-dom';
import { WrapperTable } from './stylesDefault';
import VisibilityIcon from '@mui/icons-material/Visibility';

const columns: (handleNavigate: (params: any) => void) => GridColDef[] = (handleNavigate) => [
  {
    field: 'cd_servicer',
    headerName: 'Nome Servicer',
    flex: 1
  },
  {
    field: 'dt_cessao',
    headerName: 'Data Cessão',
    flex: 1
  },
  {
    field: 'qtd_contratos',
    headerName: '# Contratos',
    flex: 1
  },
  {
    field: 'qtd_contratos_ativos',
    align: 'center',
    headerAlign: 'center',
    headerName: '# Contratos Ativos',
    flex: 1,
  },
  {
    field: 'vl_cessao_liquido',
    align: 'center',
    headerAlign: 'center',
    headerName: 'Valor Cessão Líquido',
    flex: 1
  },
  {
    field: 'details',
    align: 'center',
    headerAlign: 'center',
    headerName: 'Detalhes',
    flex: 1,
    renderCell: ({ row }) => (
      <DetailsButton
        onClick={() => handleNavigate(row)}
        variant='text'
      >
        <VisibilityIcon style={{ color: "#000" }}/>
      </DetailsButton>
    )
  }
]

const rows = [
  {
    cd_servicer: "FIDC Direto",
    dt_cessao: "28/12/2023",
    qtd_contratos: 259,
    qtd_contratos_ativos: 144,
    vl_cessao_liquido:  'R$ 11.280.881,46'

  }
]

const DataTable = ({ data }: any) => {
 const navigate = useNavigate();
  const handleNavigate = (params: any) => {
   navigate(`/analise-pos-venda`)
  }
  return (
   <WrapperTable>
     <HeaderTable>
        Operações
      </HeaderTable>
       <CustomDataGrid
        rows={rows}
        columns={columns(handleNavigate)}
        initialState={{
          pagination: {
            paginationModel: { page: 0, pageSize: 10 },
          },
        }}
        pageSizeOptions={[5, 10]}
        rowSelection={false}
        getRowId={(row) => row.cd_servicer}
      />
    </WrapperTable>
  );
}

export default DataTable;
