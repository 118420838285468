/* eslint-disable no-template-curly-in-string */
import React, { useRef, useState } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { ChartDetails, Container } from './styles';
import { IGraph } from '../../datasetInterface';

interface IInput {
  data: IGraph
}

export const ChartColumnFlux = (input: IInput) => {
  const [data, setData] = useState<any[]>([]);
  const [data2, setData2] = useState<any[]>([]);
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    let detailChart;
    const maskFill = 'rgba(0,0,255,0.05)';
    let detailData: any[] = [];
    let detailData2: any[] = [];
    const detailStart = input.data?.y[0];
  
  const options: Highcharts.Options = {
    chart: {
      reflow: false,
      zooming: {
          type: 'x'
      },
      width: 800,
      events: {

          // listen to the selection event on the master chart to
          // update the extremes of the detail chart
          selection: function (event) {
              const extremesObject = event.xAxis[0],
                  min = extremesObject.min,
                  max = extremesObject.max,
                  xAxis = this.xAxis[0];

              // reverse engineer the last part of the data
              this.series[0].data.forEach(point => {
                  if (point.x >= min && point.x < max) {
                    detailData.push([point.x, point.y]);
                  }
              });

              this.series[1].data.forEach(point => {
                  if (point.x >= min && point.x < max) {
                    detailData2.push([point.x, point.y]);
                  }
              });

              // move the plot bands to reflect the new detail
              // span
              xAxis.removePlotBand('mask-before');
              xAxis.addPlotBand({
                  id: 'mask-before',
                  from: input.data?.y[0],
                  to: min,
                  color: maskFill
              });

              xAxis.removePlotBand('mask-after');
              xAxis.addPlotBand({
                  id: 'mask-after',
                  from: max,
                  to: input.data?.y[input.data?.y.length - 1][0],
                  color: maskFill
              });

              setData(detailData)
              setData2(detailData2)

              return false;
          }
      }
    },
    plotOptions: {
      series: {
        enableMouseTracking: false
      },
    },
    title: {
      text: ''
    },
    xAxis: {
      accessibility: {
        description: 'Mês/Ano'
      },
      categories: input.data?.x,
    },
    yAxis: {
      min: 0,
      title: {
        text: "Valor (R$)"
      },
    },
    series: [{
      type: 'line',
      name: "Previsto",
      data: input.data?.y[0],
      color: "#1D1165"
    },{
      type: 'line',
      name: "Recebido",
      data: input.data?.y[1],
      color: "#F46F62"
    }]
  };

  const optionsDetails: Highcharts.Options = {
  title: {
    text: 'Fluxo de Caixa'
  },
  xAxis: {
    accessibility: {
      description: 'Mês/Ano'
    },
    categories: input.data?.x,
  },
  yAxis: {
    min: 0,
    title: {
      text: "Valor (R$)"
    },
  },
  series: [{
    type: 'line',
    name: "Previsto",
    data: data,
    color: "#1D1165",
  },
  {
    type: 'line',
    name: "Previsto",
    data: data2,
    color: "#F46F62"
  },
  ]}
  return (
   <Container>
    
    {/* <HighchartsReact
      highcharts={Highcharts}
      options={optionsDetails}
      ref={chartComponentRef}
    /> */}
    <ChartDetails>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
      />
    </ChartDetails>
   </Container>
  );
}

export const ChartColumnFluxAccumulated = (input: IInput) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
   const options: Highcharts.Options = {
     title: {
       text: 'Fluxo de Caixa Acumulado'
     },
     xAxis: {
       accessibility: {
         description: 'Mês/Ano'
       },
       categories: input.data?.x,
     },
     yAxis: {
       min: 0,
       title: {
         text: "Valor (R$)"
       },
     },
     series: [{
       type: 'line',
       name: "Previsto Acumulado",
       data: input.data?.y[2],
       color: "#1D1165"
     },{
       type: 'line',
       name: "Recebido Acumulado",
       data: input.data?.y[3],
       color: "#F46F62"
     }]
   };
   return (
    <Container>
      <HighchartsReact
       highcharts={Highcharts}
       options={options}
       ref={chartComponentRef}
     />
    </Container>
   );
 }
