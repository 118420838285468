import React from 'react';

import { Container } from './styles';
import HeaderTitle from '../../components/HeaderTitle';
import DataTable from './components/DataTable';

const PosVenda = () => {
  return (
   <Container>
    <HeaderTitle
     title={`Operações Pós Venda`}
     variant='h4'
     style={{
       marginBottom: "1rem",
     }}
    />

    <DataTable data={[]}/>
   </Container>
  );
}

export default PosVenda;