import React, { useEffect, useState } from 'react';

import { Container, SubTitle, Title } from './styles';
import { Grid } from '@mui/material';
import Card from './components/Card';
import DataTable from './components/DataTable';
import DataTableProvisionamento from './components/DataTableProvisionamento';
import DataTableDevedores from './components/DataTableDevedores';
import FiltersPost from './components/Filters';
import { api } from '../../api';
import { toast } from 'react-toastify';
import { FluxoCaixaDto, GeralDto, PostAnalysisDto } from './datasetInterface';
import { parseRS, parseInt, parsePct } from './helpers';
import ChartFluxHistory from './components/ChartFluxHistory';
import ChartProvHistory from './components/ChartProvHistory';

const AnalisePosVenda = () => {
  const [dados, setDados] = useState<PostAnalysisDto>({
    capa: { data_cessao: '', nome: 'FIDC Direto', qtd_contratos: 0, qtd_contratos_ativos: 0, valor_cessao_liquido: 0 },
    devedores: [],
    fluxo_caixa: [],
    geral: [],
    tabela_provisionamento_2682: [],
    tabela_provisionamento_direto: [],
    transicao: [],
  });
  const [geral, setGeral] = useState<GeralDto | null>({
    ano_mes: '',
    benchmark_recebimento: 0,
    data_referencia: '',
    duration_anos: 0,
    indice_adimplencia: 0,
    indice_inadimplencia: 0,
    ltv: 0,
    pct_provisionado_2682: 0,
    pct_provisionado_direto: 0,
    provisionado_direto: 0,
    qtd_contratos: 0,
    qtd_contratos_ativos: 0,
    saldo_liquido_aberto: 0,
    valor_cessao_liquido: 0,
  });
  const [fluxo, setFluxo] = useState<FluxoCaixaDto | null>({
    ano_mes: '',
    valor_esperado: 0,
    valor_esperado_acumulado: 0,
    valor_recebido: 0,
    valor_recebido_acumulado: 0,
    valor_recebido_antecipacao: 0,
    valor_recebido_inadimplencia: 0,
    valor_recebido_multas: 0,
    valor_recebido_parcela_mes: 0,
  });
  const [recAcumulado, setRecAcumulado] = useState(0);
  const [espAcumulado, setEspAcumulado] = useState(1);
  const [tabela2682, setTabela2682] = useState<any[]>([]);
  const [tabelaDireto, setTabelaDireto] = useState<any[]>([]);
  const [devedores, setDevedores] = useState<any[]>([]);
  useEffect(() => {
    getAnalysis()
      .then();
  }, []);

  const getAnalysis = async (idFilter?: any) => {
    const toastId = toast.loading('Carregando Carteira Analisada');
    try {
      const idOp = 'f3d572d24ab1499886c18a3c4c7066c9';
      const response = await api.get('/backend/operations/analysis/' + idOp);
      const json = response.data.data;
      setDados(json.analysis);
      setGeral(json.analysis.geral[json.analysis.geral.length - 1]);
      setFluxo(json.analysis.fluxo_caixa[json.analysis.fluxo_caixa.length - 1]);
      setRecAcumulado(dados.fluxo_caixa.reduce((p, c) => p + c.valor_recebido, 0));
      setEspAcumulado(dados.fluxo_caixa.reduce((p, c) => p + c.valor_esperado, 0));
      if (dados.tabela_provisionamento_2682[dados.tabela_provisionamento_2682.length - 1]) {
        setTabela2682(dados.tabela_provisionamento_2682[dados.tabela_provisionamento_2682.length - 1].lista);
      }
      if (dados.tabela_provisionamento_direto[dados.tabela_provisionamento_direto.length - 1]) {
        setTabelaDireto(dados.tabela_provisionamento_direto[dados.tabela_provisionamento_direto.length - 1].lista);
      }
      if (dados.devedores[dados.devedores.length - 1]) {
        setDevedores(dados.devedores[dados.devedores.length - 1].lista);
      }
    } catch (error) {
      console.error(error)
      toast.error('Não Foi Possível Carregar Carteira Analisada');
    } finally {
      toast.dismiss(toastId);
    }
  };
  return (
    <Container>
      <Title variant='h4'>{dados.capa.nome}</Title>

      <FiltersPost fileId={''} />

      <SubTitle variant='h5'>Visão geral</SubTitle>
      <Grid container columnSpacing={3} rowSpacing={3} mb={5}>
        <Grid item xs={12} lg={3}>
          <Card title='Data da Cessão' description={dados.capa.data_cessao} />
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card title='Valor Líquido de Cessão' description={parseRS(dados.capa.valor_cessao_liquido)} />
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card title='Benchmark de Recebimento' description={parseRS(geral?.benchmark_recebimento)} />
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card title='Saldo Líquido em Aberto' description={parseRS(geral?.saldo_liquido_aberto)} />
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card title='Contratos Ativos/Totais' description={`${parseInt(geral?.qtd_contratos_ativos)} / ${parseInt(geral?.qtd_contratos)}`} />
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card title='Duration (anos)' description={('' + geral?.duration_anos).replace('.', ',')} />
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card title='LTV' description={parsePct(geral?.ltv)} />
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card title='Provisionado' description={parsePct((geral?.pct_provisionado_direto || 0) / 100)} />
        </Grid>

      </Grid>

      <SubTitle variant='h5'>Fluxo de Caixa</SubTitle>
      <Grid container columnSpacing={3} rowSpacing={3} mb={5}>
        <Grid item xs={12} lg={3}>
          <Card title={`Esperado ${fluxo?.ano_mes}`} description={parseRS(fluxo?.valor_esperado)} />
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card title={`Recebido ${fluxo?.ano_mes}`} description={parseRS(fluxo?.valor_recebido)} />
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card title={`Recebido/Esperado ${fluxo?.ano_mes}`} description={parsePct((fluxo?.valor_recebido || 0) / (fluxo?.valor_esperado || 1))} />
        </Grid>
      </Grid>

      <Grid container columnSpacing={3} rowSpacing={3} mb={5}>
        <Grid item xs={12} lg={3}>
          <Card title='Esperado (Acumulado)' description={parseRS(espAcumulado)} />
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card title='Recebido (Acumulado)' description={parseRS(recAcumulado)} />
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card title='Recebido/Esperado (Acumulado)' description={parsePct(recAcumulado / espAcumulado)} />
        </Grid>
      </Grid>

      <DataTable data={dados.fluxo_caixa.map((x, i) => {
        return {
          id: i,
          ano_mes: x.ano_mes,
          valor_esperado: parseRS(x.valor_esperado),
          valor_recebido: parseRS(x.valor_recebido),
          valor_recebido_antecipacao: parseRS(x.valor_recebido_antecipacao),
          valor_recebido_parcela_mes: parseRS(x.valor_recebido_parcela_mes),
          valor_recebido_inadimplencia: parseRS(x.valor_recebido_inadimplencia),
        };
      })} />

      <ChartFluxHistory data={{
        x: dados.fluxo_caixa.map((c, i) => c.ano_mes),
        recebido: dados.fluxo_caixa.map((c, i) => c.valor_recebido),
        esperado: dados.fluxo_caixa.map((c, i) => c.valor_esperado),
        antecipacao: dados.fluxo_caixa.map((c, i) => c.valor_recebido_antecipacao),
      }} />

      <SubTitle variant='h5'>Provisionamento & Inadimplência</SubTitle>
      <Grid container columnSpacing={3} rowSpacing={3} mb={5}>
        <Grid item xs={12} lg={3}>
          <Card title={`% Provisionado (2682) ${geral?.ano_mes}`} description={parsePct((geral?.pct_provisionado_2682 || 0) / 100)} />
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card title={`% Provisionado (Direto) ${geral?.ano_mes}`} description={parsePct((geral?.pct_provisionado_direto || 0) / 100)} />
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card title='Índice de Inadimplência' description={('' + geral?.indice_inadimplencia).replace('.', ',')} />
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card title='Índice de Adimplência' description={('' + geral?.indice_adimplencia).replace('.', ',')} />
        </Grid>
      </Grid>

      <DataTableProvisionamento data={tabela2682.map((x, i) => {
        return {
          id: i,
          classificacao: x.classificacao,
          valor_presente: parseRS(x.valor_presente),
          valor_provisionado: parseRS(x.valor_provisionado),
          valor_liquido: parseRS(x.valor_liquido),
          qtd_contratos: parseInt(x.qtd_contratos),
        };
      })} title={`Provisionamento (2682) em ${geral?.ano_mes}`} />

      <DataTableProvisionamento data={tabelaDireto.map((x, i) => {
        return {
          id: i+100,
          classificacao: x.classificacao,
          valor_presente: parseRS(x.valor_presente),
          valor_provisionado: parseRS(x.valor_provisionado),
          valor_liquido: parseRS(x.valor_liquido),
          qtd_contratos: parseInt(x.qtd_contratos),
        };
      })} title={`Provisionamento (Direto) em ${geral?.ano_mes}`} />

      <ChartProvHistory data={{
        x: dados.geral?.map((c, i) => c.ano_mes),
        direto: dados.geral?.map((c, i) => c.pct_provisionado_direto),
        p2682: dados.geral?.map((c, i) => c.pct_provisionado_2682),
      }} />

      <SubTitle variant='h5'>Maiores Devedores</SubTitle>
      <Grid container columnSpacing={3} rowSpacing={3} mb={5}>
        <Grid item xs={12} lg={3}>
          <Card title='Top 5' description={parsePct()} />
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card title='Top 10' description={parsePct(0)} />
        </Grid>

        <Grid item xs={12} lg={3}>
          <Card title='Top 20' description={parsePct(0)} />
        </Grid>
      </Grid>

      <DataTableDevedores data={devedores?.map((x,i) => {
        console.log(x)
        return {
          id: i,
          nome: x.nome,
          valor: parseRS(x.valor)
        }
      })} />
    </Container>
  );
};

export default AnalisePosVenda;
