import { Box, Typography, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const WrapperTable = styled(Box)`
  display: flex;
  flex-direction: column;
  background-color: #FFF;
  border-radius: 8px;
  margin-bottom: 5rem;
  padding: 0 1rem;
`;

export const HeaderBox = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const CustomDataGrid = styled(DataGrid)`
  background-color: #FFF;

.MuiDataGrid-main > .MuiDataGrid-withBorderColor {
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
}

.MuiDataGrid-columnHeaderTitle {
 font-weight: 600;
}

.MuiDataGrid-footerContainer {
  border-bottom: 2px solid #000;
}

    border: none;
    border-bottom: 1px solid #000;
`;

export const Footer = styled(Box)`
  display: flex;
  flex-direction: column;
`;
