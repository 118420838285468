import { GridColDef } from '@mui/x-data-grid';
import React from 'react';

import { CustomDataGrid, HeaderTable } from './styles';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { WrapperTable } from './stylesDefault';
import { Typography } from '@mui/material';
import { parseDate } from '../../../AnaliseCarteira/datasets/helpers';

const columns: GridColDef[] = [
  { field: 'nome', headerName: 'Nome', flex: 2 },
  { field: 'valor', headerName: 'Valor', flex: 1 },
]

interface IDataTableDevedores {
 data: Array<any>;
}

const dataLocal = [
  {
    id: 1,
    nome: "CONSTRUTORA RANDMIL LTDA/ 44.274.780/0001-44",
    valor: "R$ 291.861,19"
  },{
    id: 3,
    nome: "ROSILDA DA SILVA SABEL/ 104.608.452-68",
    valor: "R$ 233.961,82"
  },{
    id: 4,
    nome: "MARCIO VINICIUS BECKMANN SANTOS SILVA/ 844.859.653-68",
    valor: "R$ 210.681,80"
  },{
    id: 5,
    nome: "INGRID CATUNDA DE CASTRO/ 061.815.533-39",
    valor: "R$ 166.349,79"
  }
]

const DataTableDevedores = ({ data }: any) => {
  return (
   <WrapperTable>
     <HeaderTable>
        Maiores Devedores
      </HeaderTable>
       <CustomDataGrid
        rows={data}
        columns={columns}
        rowSelection={false}
        hideFooterPagination={true}
      />
    </WrapperTable>
  );
}

export default DataTableDevedores;
