import { Box, Typography, styled } from "@mui/material";

export const Container = styled(Box)`
 display: flex;
 flex: 1;
 flex-direction: column;
 padding: 7rem 0 0 3rem;
`

export const WrapperCardInfo = styled(Box)`
 display: flex;
 padding: 1rem;
`;

export const CardInfo = styled(Box)`
 display: flex;
 flex: 1;
 background-color: #FFF;
 padding: 1rem;
 border-radius: 8px;
 box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
 flex-direction: column;
 max-width: 1000px;
`;

export const HeaderCard = styled(Box)`
 display: flex;
 flex: 1;
 margin-bottom: 1rem;
`;

export const HeaderCardText = styled(Typography)`
 font-size: 2rem;
`;

export const HeaderContent = styled(Box)`
 display: flex;
 flex: 1;
 gap: 2rem;
`;

export const SectionLeft = styled(Box)`
 display: flex;
 flex: 1;
 flex-direction: column;
`;

export const SectionRight = styled(Box)`
 display: flex;
 flex: 1;
 flex-direction: column;
`;

export const SectionRow = styled(Box)`
 display: flex;
 flex: 1;
 margin-bottom: 1rem;
 justify-content: flex-start;
 align-items: center;
`;

export const SectionTitle = styled(Typography)`
 font-weight: 300;
 font-size: 1.1rem;
 margin-right: 0.5rem;
`;

export const SectionText = styled(Typography)`
 font-weight: 500;
 font-size: 1.1rem;
`;