import { GridColDef } from '@mui/x-data-grid';
import React from 'react';

import { CustomDataGrid, HeaderTable } from './styles';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { WrapperTable } from './stylesDefault';
import { Typography } from '@mui/material';
import { parseDate } from '../../../AnaliseCarteira/datasets/helpers';

const columns: GridColDef[] = [
  { field: 'ano_mes', headerName: 'Ano-mês', flex: 1 },
  { field: 'valor_esperado', headerName: 'Esperado a Receber', flex: 2 },
  { field: 'valor_recebido', headerName: 'Recebido Mês', flex: 2 },
  { field: 'valor_recebido_antecipacao', headerName: 'Recebido de antecipação', flex: 3 },
  { field: 'valor_recebido_parcela_mes', headerName: 'Recebido de parcela do mês', flex: 3 },
  { field: 'valor_recebido_inadimplencia', headerName: 'Recebido de inadimplência', flex: 3 },
]

interface IDataTable {
 data: Array<any>;
}

const dataLocal = [
  {
    id: 1,
    ano_mes: "07/2024",
    valor_esperado: "R$ 1.095.786,00",
    valor_recebido: "R$ 500.000,00",
    valor_recebido_antecipacao: "R$ 75.000,00",
    valor_recebido_parcela_mes: "R$ 352.987,54",
    valor_recebido_inadimplencia: "R$ 88.621.879,53"
  }
]

const DataTable = ({ data }: any) => {
 const navigate = useNavigate();
  const handleSelectItem = (params: any) => {
   // navigate({
   //  pathname: "/file-pos",
   //  search: createSearchParams({
   //   data: params.row,
   //  }).toString()
   // })
  }
  return (
   <WrapperTable>
     <HeaderTable>
        Fluxo de Caixa
      </HeaderTable>
       <CustomDataGrid
        rows={data}
        columns={columns}
        hideFooterPagination={true}
        rowSelection={false}
        onRowClick={handleSelectItem}
      />
    </WrapperTable>
  );
}

export default DataTable;
