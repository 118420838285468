import { Box, Button, Typography, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

export const CustomDataGrid = styled(DataGrid)`
 background-color: #FAFAFA;

 .MuiDataGrid-main > .MuiDataGrid-withBorderColor {
   border-top: 1px solid #000;
   border-bottom: 1px solid #000;
 }

 .MuiDataGrid-columnHeaderTitle {
  font-weight: 600;
 }

 border: none;
`;

export const HeaderTable = styled(Typography)`
 padding: 1rem;
 font-weight: 400;
 font-size: 1.5rem;
`;

export const DetailsButton = styled(Button)`
  text-transform: none;
`;