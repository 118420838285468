import { GridColDef } from '@mui/x-data-grid';
import React from 'react';

import { CustomDataGrid, HeaderTable } from './styles';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { WrapperTable } from './stylesDefault';
import { Typography } from '@mui/material';
import { parseDate } from '../../../AnaliseCarteira/datasets/helpers';

const columns: GridColDef[] = [
  { field: 'classificacao', headerName: 'Classificação', flex: 1 },
  { field: 'valor_presente', headerName: 'Valor Presente', flex: 1 },
  { field: 'valor_provisionado', headerName: 'Valor Provisionado', flex: 1 },
  { field: 'valor_liquido', headerName: 'Valor Líquido', flex: 1 },
  { field: 'qtd_contratos', headerName: '# Contratos', flex: 1 },
]

interface IDataTableProvisionamento {
 data: Array<any>;
 title: string;
}

const dataLocal = [
  {
    id: 1,
    classificacao: "AA",
    valor_presente: "R$ 4.158.504,52",
    valor_provisionado: "R$ 0,00",
    valor_liquido: "R$ 4.158.504,52",
    qtd_contratos: "37",
  }
]

const DataTableProvisionamento = ({ data, title }) => {
  return (
   <WrapperTable>
     <HeaderTable>
        {title}
      </HeaderTable>
       <CustomDataGrid
        rows={data}
        columns={columns}
        hideFooterPagination={true}
        rowSelection={false}
      />
    </WrapperTable>
  );
}

export default DataTableProvisionamento;
