import React, { useState } from 'react';
import { Box, Button, Divider, Grid, InputAdornment, InputLabel, MenuItem, TextField } from '@mui/material';
import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
import Accordion  from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CustomAccordion, CustomAccordionDetails, CustomDatePicker, CustomSelect, FilterButton } from './styles';
import { Form } from './stylesDefault';
import { FormProvider, useForm } from 'react-hook-form';
import InputText from '../../../../components/InputText';
import { toast } from 'react-toastify';
import { api } from '../../../../api';
import { useNavigate } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

interface IFiltersPostProps {
  fileId: string;
}

const FiltersPost = ({ fileId }: IFiltersPostProps) => {
  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      name: null,
      data_maxima_vencimento: null,
      valor_nominal_parcela_minimo: null,
      saldo_devedor_minimo: null,
      saldo_devedor_maximo: null,
      qtd_parcelas_abertas_minima: null,
      aging_maximo: null,
      saldo_minimo_spe: null,
      ltv_maximo: null,
      prazo_minimo: null,
      tir_minima: null,
      atraso_maximo_npl: null,
    }
});
 const [expanded, setExpanded] = useState<boolean>(false);
 const defaultState = {
  carregando: false,
  padrao: true,
  salvoId: 0,
  filtrado: false,
  nomeFiltro: null,
 }
 const [state, setState] = useState(defaultState);
 const transientState = Object.assign({}, defaultState);

 const filtrar = async(data: any) => {
  let {padrao , salvoId, ...others} = transientState;
  padrao = Object.values(others).every(x => !x)
  setState({...state, ...transientState, padrao, carregando: false, filtrado: !padrao, ...data });
  salvarFiltro(data);
 }

 const salvar = () => {
  if (!transientState.nomeFiltro) return;
  setState({ ...state, nomeFiltro: transientState.nomeFiltro, salvoId: Math.round(Math.random()*500), padrao: false, filtrado: true});
 }

 const publicar = () => {

 }

 const salvarFiltro = async(data: any) => {
  const toastId = toast.loading("Salvando Filtro")
  try {
    //await api.post(`/backend/portfolios/analysis/files/${fileId}/filters`, data)
    //navigate(`/status-filtro/${fileId}`);
  }catch(err){
    toast.error("Falha ao salvar o filtro");
  } finally {
    toast.dismiss(toastId);
    methods.reset();
  }
 }

  return (
   <Box sx={{ marginBottom: expanded ? 3 : 0}}>
    <CustomAccordion
      expanded={expanded}
      onChange={() => setExpanded(value => !value)}
    >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
        >
          <Typography>Filtros</Typography>
        </AccordionSummary>
        <CustomAccordionDetails>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Form onSubmit={methods.handleSubmit(filtrar)}>
            <FormProvider {...methods}>
              <Grid container  columnSpacing={10}>

                <Grid item xs={12} lg={6}>
                  <InputLabel id="filtro-cedente">Cedente</InputLabel>
                  <CustomSelect
                    value={[]}
                    id="filtro-cedente"
                    defaultValue={0}
                  >
                    <MenuItem value={0}>Consolidado</MenuItem>
                    <MenuItem value={1}>BP8</MenuItem>
                    <MenuItem value={2}>Victa</MenuItem>
                  </CustomSelect>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <InputLabel id="filtro-op">Operação</InputLabel>
                  <CustomSelect
                    value={[]}
                    id="filtro-op"
                    defaultValue={0}
                  >
                    <MenuItem value={1}>OP008</MenuItem>
                    <MenuItem value={2}>OP009</MenuItem>
                    <MenuItem value={2}>OP013</MenuItem>
                  </CustomSelect>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <InputLabel id="filtro-produto">Produto</InputLabel>
                  <CustomSelect
                    value={[]}
                    id="filtro-produto"
                    defaultValue={0}
                  >
                    <MenuItem value={1}>Pró-Soluto</MenuItem>
                    <MenuItem value={2}>Tabela Direta</MenuItem>
                  </CustomSelect>
                </Grid>

                <Grid item xs={12} lg={6}>
                  <InputLabel id="filtro-data">Mês Referência</InputLabel>
                  <CustomDatePicker openTo="month" views={["year","month"]} />
                </Grid>
                <Grid item xs={12} lg={4}>&nbsp;</Grid>
              </Grid>
              <div>
                <FilterButton type='submit' variant="contained" disabled={state.carregando}>{ state.carregando ? 'Carregando...' : 'Filtrar' }</FilterButton>
              </div>
            </FormProvider>
          </Form>
          </LocalizationProvider>
        </CustomAccordionDetails>
      </CustomAccordion>
   </Box>
  );
}

export default FiltersPost;
