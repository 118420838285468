
import { Button, Checkbox } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
 display: grid;
 height: 100vh;
 grid-template-columns: 1fr 1fr;

 @media(max-width: 1500px) {
  grid-template-columns: 1fr;
 }
`;

export const SectionSignIn = styled.section`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 1rem 0;
`;

export const SpaceSignIn = styled.div``;

export const AsideLogo = styled.img`
  background-color: black;
  width: 100%;
  height: 100vh;
  filter: grayscale(1);
  
  @media(max-width: 1500px) {
    display: none;
  }
`;

export const AsideForm = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  button {
    margin-top: 3rem;
  }
`;

export const PortalGif = styled.img`
  max-width: 100%;
  max-height: 100%;
`;

export const Form = styled.div``;

export const ForgotPassword = styled.span`
 text-decoration: underline;
 font-size: 1rem;
 font-weight: 300;
 margin-top: 1rem;
`;

export const ContainerLogoDireto = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-end;
  padding-right: 1rem;
`;

export const LogoDireto = styled.img`
  width: 10rem;
`;

export const ModalContainer = styled.div`
  width: 700px;
  height: 500px;
  background-color: #FFF;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  gap: 1rem;
`;

export const WrapperInput = styled.div`
  display: flex;
  margin-top: 3rem;

  @media(max-width: 1200px) {
    max-width: 500px;
  }

  @media(max-width: 1023px) {
    max-width: 450px;
  }
`;

export const CustomTextField = styled.input`
  background-color: #FFF;
  width: 500px;
  height: 60px;
  border-width: 1px;
  border-top-right-radius: 174px;
  border-bottom-right-radius: 174px;
  padding: 1rem;
`;

export const ButtonCloseModalNewPassword = styled.button`
  padding: 1rem 2rem;
  background: #F46F62;
  border: 1px solid #F46F62;
  color: #FFF;
  border-radius: 25px;
`;
