import React from 'react';

import { CardInfo, Container, HeaderCard, HeaderCardText, HeaderContent, SectionLeft, SectionRight, SectionRow, SectionText, SectionTitle, WrapperCardInfo } from './styles';
import HeaderTitle from '../../components/HeaderTitle';
import { Divider } from '@mui/material';

const PosVendaDetalhe = () => {
  const obj: any = {};
  return (
   <Container>
    <HeaderTitle 
     title='Acompanhamento de Operação'
     style={{
      marginBottom: '2rem'
     }}
    />

    <WrapperCardInfo>
     <CardInfo>
      <HeaderCard>
       <HeaderCardText>Sessão</HeaderCardText>
      </HeaderCard>
      <Divider style={{ marginBottom: 16 }}/>

      <HeaderContent>
       <SectionLeft>
        <SectionRow>
         <SectionTitle>Nome Servicer:</SectionTitle>
         <SectionText>{obj.cd_servicer ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Nome da Cessão:</SectionTitle>
         <SectionText>{obj.nm_cessao ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Servicer:</SectionTitle>
         <SectionText>{obj.nm_servicer ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Cessionario:</SectionTitle>
         <SectionText>{obj.nm_cessionario ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Backup Servicer:</SectionTitle>
         <SectionText>{obj.nm_backup_servicer ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Diligência Financeira:</SectionTitle>
         <SectionText>{obj.nm_diligencia_financeira ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Diligência Jurídica:</SectionTitle>
         <SectionText>{obj.nm_diligencia_juridica ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Data de Assinatura do Contrato:</SectionTitle>
         <SectionText>{obj.dt_assinatura_do_contrato ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Data Cessão:</SectionTitle>
         <SectionText>{obj.dt_cessao ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Quantidade de Contratos:</SectionTitle>
         <SectionText>{obj.qtd_contratos ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Tipos de Contratos:</SectionTitle>
         <SectionText>{obj.ds_tipo_contratos ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Valor Nominal Total:</SectionTitle>
         <SectionText>{obj.vl_nominal_total ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Valor Cessão Bruto:</SectionTitle>
         <SectionText>{obj.vl_cessao_bruto ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Securitizadora:</SectionTitle>
         <SectionText>{obj.nm_securitizadora ?? 'Lorem'}</SectionText>
        </SectionRow>
       </SectionLeft>

       <Divider orientation='vertical'/>

       <SectionRight>
        <SectionRow>
         <SectionTitle>Custodiante:</SectionTitle>
         <SectionText>{obj.nm_custodiante ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Agente Fiduciário:</SectionTitle>
         <SectionText>{obj.nm_agente_fiduciario ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Prazo Notificação:</SectionTitle>
         <SectionText>{obj.qt_prazo_notificacao ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Períodicidade Relatório S:</SectionTitle>
         <SectionText>{obj.ds_periodicidade_relatorio_s ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Períodicidade Relatório BS:</SectionTitle>
         <SectionText>{obj.ds_periodicidade_relatorio_bs ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Período de Apuração:</SectionTitle>
         <SectionText>{obj.ds_periodo_de_apuracao ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Data Envio Relatório:</SectionTitle>
         <SectionText>{obj.dt_envio_relatorio_bs ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Serviço Prestado:</SectionTitle>
         <SectionText>{obj.ds_servico_prestado ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Remuneração Diligência:</SectionTitle>
         <SectionText>{obj.vl_remuneracao_diligencia ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Tipo Remuneração BS:</SectionTitle>
         <SectionText>{obj.ds_tipo_remuneracao_bs ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Spread de Cessão:</SectionTitle>
         <SectionText>{obj.ds_spread_de_cessao ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Injeção:</SectionTitle>
         <SectionText>{obj.dt_injection ?? 'Lorem'}</SectionText>
        </SectionRow>

        <SectionRow>
         <SectionTitle>Operação:</SectionTitle>
         <SectionText>{obj.nm_operacao ?? 'Lorem'}</SectionText>
        </SectionRow>
       </SectionRight>
      </HeaderContent>
     </CardInfo>
    </WrapperCardInfo>
   </Container>
  );
}

export default PosVendaDetalhe;