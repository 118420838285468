/* eslint-disable no-template-curly-in-string */
import React, { useRef } from 'react';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { Container } from './styles';
import { IGraph } from '../../datasetInterface';

interface IInput {
  data: IGraph;
}

const ChartProvHistory = (input: any) => {
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
  const options: Highcharts.Options = {
    title: {
      text: 'Histórico de Provisionamento',
    },
    xAxis: {
      crosshair: true,
      accessibility: {
        description: 'Mês/Ano',
      },
      categories: input.data.x,
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Provisionamento (%)',
      },
    },
    series: [{
      type: 'line',
      name: 'Provisionamento (Direto)',
      data: input.data.direto,
      color: '#F46F62',
    }, {
      type: 'line',
      name: 'Provisionamento (2682)',
      data: input.data.p2682,
      color: '#1D1165',
    }],
  };
  return (
    <Container>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
        ref={chartComponentRef}
      />
    </Container>
  );
};

export default ChartProvHistory;
